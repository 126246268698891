
<template>
  <div class="box">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="search()">
      <table class="detailTable_search" style="width: 100%">
        <tr>
          <td class="titleTd width80px">日期</td>
          <td class="valueTd">
            <div class="block">
              <el-date-picker v-model="dataForm.watchTime" type="datetime" placeholder="选择日期时间" align="right"
                :picker-options="pickerOptions" :default-value="new Date()" format="yyyy-MM-dd HH"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </div>
          </td>
          <td class="titleTd width80px">区域</td>
          <td class="valueTd">
            <region-tree-jurisdiction :rootId="gridId !== '' ? gridId : regionId" placeholder="请选择区域"
              v-model="dataForm.regionId" @typeChange="typeChange"> </region-tree-jurisdiction>
          </td>
        </tr>
      </table>
    </el-form>
    <div class="wxts_msg_search">
      <span style="margin-left: 15px">截止统计时间:{{ jzrq }}</span>
      <div class="f_t">
        <el-button-group>
          <!--<el-button type="success" v-if="isAuth('dataflow:olfonrealuser:list')" @click="currentChangeHandle(1)"-->
          <!--:disabled="dataListLoading">{{ $t('query') }}</el-button>-->
          <el-button size="mini" type="success" @click="search(dataForm.type)" :disabled="dataListLoading">查询</el-button>
          <el-button size="mini" type="warning" @click="exportData()">{{ $t('export') }}</el-button>
          <el-button size="mini" type="primary" @click="goBack()" icon="el-icon-back" v-if="orgIdList.length > 0"
            :disabled="dataListLoading">返回</el-button>
        </el-button-group>
      </div>
    </div>
    <div class="">
      <div v-loading="dataListLoading" id="newwatchOneId" class="watchOneId"></div>
      <el-table :data="dataList" style="width: 100%" :summary-method="getSummaries" show-summary>
        <!-- {{ dataList }} -->
        <el-table-column label="区域" align="center" style="color:#f5f7fa">
          <template slot-scope="scope">
          <!-- <org :value="scope.row.areaCode" @click.native="area(scope.row.areaCode)" class="count-convert">

              </org> -->

            <a style="color: blue;cursor: pointer" @click="area(scope.row.areaCode)"
              v-if="scope.row.type != 4">{{ scope.row.regionName }}</a>
            <span v-else>{{ scope.row.regionName }}</span>

          </template>
        </el-table-column>

        <!-- <el-table-column label="统计" align="center"> -->
        <el-table-column prop="realSumYear" label="年" align="center">
          <template slot-scope="scope">
            {{ scope.row.realSumYear }}
          </template>
        </el-table-column>
        <el-table-column prop="realSumMonth" label="月" align="center">
          <template slot-scope="scope">
            {{ scope.row.realSumMonth }}
          </template>
        </el-table-column>
      <!-- <el-table-column prop="leveltwo" label="周" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.realSumWeek }}</span>
            </template>
            </el-table-column> -->
        <el-table-column prop="realSumDay" label="日" align="center">
          <template slot-scope="scope">
            {{ scope.row.realSumDay }}
          </template>
        </el-table-column>
        <el-table-column prop="realSumHour" label="时" align="center">
          <template slot-scope="scope">
            {{ scope.row.realSumHour }}
          </template>
        </el-table-column>
      <!-- <el-table-column prop="leveltwo" label="合计" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.realSumCount }}</span>
            </template>
            </el-table-column> -->
        <!-- </el-table-column> -->
      </el-table>
    </div>

  </div>
</template>

<script>
import { getList, exportExcel, exportToExcel, realTimeCount } from '@/api/kanban/realTime.js'
import { throws } from 'assert';
export default {
  data() {
    return {
      dataListLoading: false,
      jzrq: '',
      orgIdList: [],
      dataForm: {
        watchTime: '', //时间
        regionId: '',
        type: ''
      },
      dataList: [],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },

  components: {

  },
  //在vue对象存活的情况下，进入当前存在activated()函数对象的页面时，一进入页面就进行触发，可以用来初始化页面
  activated() {
    let pageIndex = this.$route.query.pageIndex;
    if (pageIndex) {
      this.pageIndex = parseInt(pageIndex);
    }
    let pageSize = this.$route.query.pageSize;
    if (pageSize) {
      this.pageSize = parseInt(pageSize)
    }
    this.getDataList();
  },

  created() {
    this.getNowDate()
  },

  mounted() {
  },

  updated() {

  },

  computed: {
    regionId: {
      get() {
        const newregionId = this.$store.state.user.regionId;
        if (newregionId !== undefined) {
          return newregionId;
        } else {
          return ''
        }
      }
    },
    gridId: {
      get() {
        const newgridId = this.$store.state.user.gridId;
        if (newgridId !== undefined) {
          return newgridId;
        } else {
          return ''
        }
      }
    },
    regionCode: {
      get() {
        return this.$store.state.user.regionCode;
      }
    },
    gridCode: {
      get() {
        return this.$store.state.user.gridCode;
      }
    },
  },

  methods: {
    /**
     * 查询当天日期
     */
    getNowDate() {
      const timeOne = new Date()
      const year = timeOne.getFullYear()
      let month = timeOne.getMonth() + 1
      let day = timeOne.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      const NOW_MONTHS_AGO = `${year}-${month}-${day}`
      this.jzrq = NOW_MONTHS_AGO
      return NOW_MONTHS_AGO
    },
    // 地区钻取
    area(areacode) {
      if (this.dataForm.regionId == '') {
        this.orgIdList.push(this.regionId)
      } else {
        this.orgIdList.push(this.dataForm.regionId)
      }
      this.dataForm.regionId = areacode
      this.getDataList(areacode)
    },
    goBack() { // 回退
      this.dataForm.regionId = this.orgIdList[this.orgIdList.length - 1]
      this.getDataList(this.dataForm.regionId);
      this.orgIdList.splice(this.orgIdList.length - 1, 1)
    },

    //查询获取数据列表
    getDataList(areacode) {
      const _this = this;
      if (areacode === undefined) {
        this.dataListLoading = true;
        const params_ = {
          'selectDateTime': this.dataForm.watchTime,
          'areaCode': this.dataForm.regionId ? this.dataForm.regionId : (this.gridId !== '' ? this.gridId : this.regionId),
        }
        getList(params_).then(({ data }) => {
          if (data && data.code === 0) {
            _this.dataList = data.data
            const newList = []
            _this.dataList.forEach(element => {
              newList.push(element.regionName)
            });
            _this.watchChart(_this.dataForm.watchTime,newList, _this.dataList)
          } else {
            this.dataList = []
            this.$message.error(data.msg)
          }
        }).catch((err) => {
        }).finally(() => {
          this.dataListLoading = false;
        })
      } else {
        this.dataListLoading = true;
        const params_ = {
          'selectDateTime': this.dataForm.watchTime,
          'areaCode': areacode,
        }
        getList(params_).then(({ data }) => {
          if (data && data.code === 0) {
            _this.dataList = data.data
            const newList = []
            _this.dataList.forEach(element => {
              newList.push(element.regionName)
            });
            _this.watchChart(_this.dataForm.watchTime,newList, _this.dataList)
          } else {
            this.dataList = []
            this.$message.error(data.msg)
          }
        }).catch((err) => {
        }).finally(() => {
          this.dataListLoading = false;
        })
      }
    },
    refresh() {
    },
    search(type) {
      if (type === '4') {
        this.$message({
          message: '该区域无下属网格，不能查询！',
          type: 'warning'
        })
      } else {
        this.getDataList()
      }

    },
    typeChange(data) {
      this.dataForm.type = data.type
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
      this.refresh()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
      this.refresh()
    },
    //多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    handleChange() {
    },
    expandChange() {
    },
    exportData() {
      let config = {
        areaCode: this.dataForm.regionId ? this.dataForm.regionId : (this.gridId !== '' ? this.gridId : this.regionId),
      }
      exportToExcel(config);
    },
    watchChart(watchTime,newList, datas) {
      const _this=this
      var myChart = this.$echarts.init(document.getElementById('newwatchOneId'));
      var option;
      // option = {
      //   legend: {},
      //   tooltip: {},
      //   dataset: {
      //     source: [
      //       ...e
      //     ]
      //   },
      //   dataZoom: {
      //     show: true,
      //     realtime: true,
      //     // y: 36,
      //     height: 5,
      //     start: 0,
      //     end: 80
      //   },
      //   xAxis: [
      //     {
      //       type: 'category',
      //       // inverse: true,
      //       // axisLabel: {
      //       //   interval: 0,
      //       //   rotate: 40
      //       // }
      //     }
      //   ],
      //   yAxis: {
      //     name: '单位：人'
      //   },
      //   series: [
      //     // {
      //     //   name: '合计',
      //     //   type: 'bar',
      //     //   encode: {
      //     //     x: 1,
      //     //     y: 2
      //     //   },
      //     //   label: {
      //     //     show: true,
      //     //     position: 'top',
      //     //     valueAnimation: true
      //     //   }
      //     // },
      //     {
      //       name: '实时',
      //       type: 'bar',
      //       encode: {
      //         x: 1,
      //         y: 6
      //       },
      //       label: {
      //         show: true,
      //         position: 'top',
      //         valueAnimation: true
      //       }
      //     },
      //
      //   ]
      // };
      option = {
        //滚动条
        dataZoom: {
          show: true,
          // realtime: true,
          height: 5,
          bottom: 10,
          start: 0,
          end: 50,
          showDetail: false
        },
        legend: {
          data: ['实时'],
          orient: 'horizontal',
          y: 'top',
          x: 'center',
          // itemGap: 40
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: (params) => {
            return params[0].name + '<br>' +
              params[0].marker + ' ' + params[0].seriesName + ': ' + params[0].data + ' 个' + '<br>'
          },
        },
        grid: {
          bottom: '10',
          top: "100",
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0,//使x轴文字显示全
            rotate: 40,
            lineHeight: 18,
            formatter: function (params) {
              var newParamsName = ''// 最终拼接成的字符串
              var paramsNameNumber = params.length// 实际标签的个数
              var provideNumber = 10// 每行能显示的字的个数
              // 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
              if (paramsNameNumber > provideNumber) {
                // ********重点在这里********
                newParamsName = params.substring(0, 10) + '..'// 最终拼成的字符串
              } else { // 将旧标签的值赋给新标签
                newParamsName = params
              }
              // 将最终的字符串返回
              return newParamsName
            }
          },
          data: newList
        },
        yAxis: {

        },
        series: [{
          name: '实时',
          type: 'bar',
          barWidth: 20,//柱图宽度
          data: (function () {
            var data = [];
            datas.forEach(function (item) {
              // console.log(_this.formatter(new Date(), 'yyyy-MM-dd'))
              // console.log("123=>"+watchTime.substr(0,10))
              // console.log(watchTime)
              if(watchTime===''){
                data.push(item.realSumHour);
              }else if(watchTime.substr(0,10)===_this.formatter(new Date(), 'yyyy-MM-dd')){
                data.push(item.realSumHour);
              }else{
                data.push(item.realSumDay);
              }        
            })
            return data;
          })()
        }]

      };
      setInterval(function () {
            // run();
          }, 30000);
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      })
      this.dataListLoading = false;
    },
    getObjectValues(object) {
      var values = [];
      for (var property in object)
        values.push(object[property]);
      return values;
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ''
        } else {
          sums[index] = 'N/A'
        }
      })
      return sums
    },
    formatter (thistime, fmt) {
      let $this = new Date(thistime)
      let o = {
        'M+': $this.getMonth() + 1,
        'd+': $this.getDate(),
        'h+': $this.getHours(),
        'm+': $this.getMinutes(),
        's+': $this.getSeconds(),
        'q+': Math.floor(($this.getMonth() + 3) / 3),
        'S': $this.getMilliseconds()
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, ($this.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
      }
      return fmt
    },
  }
}
</script>

<style scoped>
.box {
  width: 100%;
  height: 100%;
}

.watchOneId {
  margin-top: 20px;
  width: 100%;
  height: 350px;
}

.stateClass {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
}

.b-sousuo {
  display: flex;
}

/* .block{
    margin-right: 10px;
  } */
</style>
